<template>
<div class="container-md col-lg-8 col-md-8">
    <AddOrganisation @submit="registerOrganisation"/>
</div>
</template>
<script>
import AddOrganisation from "@/containers/Organisation/AddOrganisation";
import { mapActions } from 'vuex';
export default {
  components: {
    AddOrganisation,
  },
  methods:{
    ...mapActions(["addOrganisation"]),
    registerOrganisation(data){
      this.addOrganisation(data);
    }
  }
};
</script>